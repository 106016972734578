
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				blue;
@link-hover-color:			red;
@link-hover-decoration:		underline;

@font-family-base:			times new roman;
@font-size-base:			14px;
@line-height-base:			1;

@font-size-h1:				16px;
@font-size-h2:				16px;
@headings-small-color:		#fff;

@thumb-width:				242px;
@thumb-height:				182px;
@thumb-margin-top:			2px;
@thumb-margin-right:		3px;
@thumb-margin-bottom:		5px;
@thumb-margin-left:			3px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #f00;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				14px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				16px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		none;

@parent-alert-padding:		10px;
@parent-alert-margin:		10px auto;
@parent-alert-border:		2px solid #000;
@parent-alert-font-size:	11px;
@parent-alert-color:		#000;
@parent-alert-bg:			transparent;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	margin: 0 auto 5px;
	a {
		color: #000;
	}
}
h2 {
	text-align: center;
	margin: 0 auto 5px;
}
#language-switcher {
	position: absolute;
	top: 0;
	right: 10px;
}
.mozaique {
	text-align: left;
}
.thumb-block {
	p {
		font-size: 12px;
		line-height: 15px;
		a {
			color: #000;
			font-weight: bold;
			text-decoration: none;
		}
	}
}
#sites-list {
	a {
		color: #000;
		text-decoration: none;
	}
}